<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="success" v-on="on">
              <v-icon size="25" dark>mdi-bell-outline</v-icon>
              <v-badge
                bordered
                content="3"
                overlap
                style="margin: 5px 10px 0px 10px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card outlined>
        <v-toolbar flat dense>
          <v-toolbar-title>
            {{ _decode(subscriber) | capitalize }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          {{ _decode(date) | myDate }}
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs v-model="tabs" grow>
            <v-tab key="detail">initial complaint</v-tab>
            <v-tab key="more_info">more info</v-tab>
            <v-tab key="evidence">evidence</v-tab>
            <v-tab key="prescription">prescription</v-tab>
            <v-tab key="labs">labs</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item key="detail" style="padding-top: 15px">
              <v-card outlined style="margin-bottom: 20px">
                <v-card-subtitle style="padding: 7px 16px 0px">
                  Initial Complaints
                </v-card-subtitle>
                <v-card-text> {{ details.initial_complaints }} </v-card-text>
              </v-card>

              <div class="mylist">
                <ul>
                  <li>
                    <div class="myleft">meeting location</div>
                    <div class="myright">{{ details.meeting_location }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">start</div>
                    <div class="myright">{{ details.time_start | myTime }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">end</div>
                    <div class="myright">{{ details.time_end | myTime }}</div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">accepted</div>
                    <div class="myright">
                      {{ details.accepted ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">more information</div>
                    <div class="myright">
                      {{ details.more_information ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">cancelled</div>
                    <div class="myright">
                      {{ details.cancelled ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">done</div>
                    <div class="myright">{{ details.done ? "Yes" : "No" }}</div>
                    <div class="clearall"></div>
                  </li>
                </ul>
              </div>
            </v-tab-item>
            <v-tab-item key="more_info" style="padding-top: 15px">
              <v-data-table
                :loading="loading"
                loader-height="1"
                :headers="headers"
                :items="moreList"
                :items-per-page="10"
              >
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="evidence" style="padding-top: 15px">
              <v-data-iterator :items="evidenceList">
                <template v-slot:default="props">
                  <v-row>
                    <v-col
                      v-for="item in props.items"
                      :key="item.id"
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                    >
                      <v-card flat outlined>
                        <v-img
                          height="350"
                          :src="pix(item.evidence)"
                          :title="item.description"
                        ></v-img>
                        <v-card-text class="card_list">
                          <ul>
                            <li>
                              <div class="myleft">information type</div>
                              <div class="myright">
                                {{
                                  truncateString(item.information_typeName, 22)
                                }}
                              </div>
                              <div class="clearall"></div>
                            </li>
                            <li>
                              <div class="myleft">description</div>
                              <div class="myright">
                                {{ truncateString(item.description, 25) }}
                              </div>
                              <div class="clearall"></div>
                            </li>
                            <li>
                              <div class="myleft">submitted</div>
                              <div class="myright">
                                {{ item.created | myDating }}
                              </div>
                              <div class="clearall"></div>
                            </li>
                          </ul>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-tab-item>
            <v-tab-item key="prescription" style="padding-top: 15px">
              <v-data-table
                :headers="preheaders"
                :items="prescribeList"
                :items-per-page="5"
              >
              </v-data-table>
            </v-tab-item>
            <v-tab-item key="labs" style="padding-top: 15px">
              <!-- start -->
              <v-row>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">urine</div>
                      <v-spacer></v-spacer>
                      <div class="myright">
                        <v-icon :color="view.urine ? 'blue' : 'red'">{{
                          view.urine ? "mdi-check" : "mdi-close"
                        }}</v-icon>
                      </div>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleftmytitle">tissue</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.tissue ? 'blue' : 'red'">{{
                        view.tissue ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">swab</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.swab ? 'blue' : 'red'">{{
                        view.swab ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">sputim</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.sputim ? 'blue' : 'red'">{{
                        view.sputim ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">fluids</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.fluids ? 'blue' : 'red'">{{
                        view.fluids ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">faeces</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.faeces ? 'blue' : 'red'">{{
                        view.faeces ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">blood</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.blood ? 'blue' : 'red'">{{
                        view.blood ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">cytology</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.cytology ? 'blue' : 'red'">{{
                        view.cytology ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">urgency</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.urgency ? 'blue' : 'red'">{{
                        view.urgency ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-sheet outlined rounded>
                    <v-card-actions>
                      <div class="myleft mytitle">fasting</div>
                      <v-spacer></v-spacer>
                      <v-icon :color="view.fasting ? 'blue' : 'red'">{{
                        view.fasting ? "mdi-check" : "mdi-close"
                      }}</v-icon>
                    </v-card-actions>
                  </v-sheet>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      drug therapy
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.drug_therapy }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      last Dose
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.last_dose }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      last Dose Date
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.last_dose_date_time | myDate }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      biochemistry
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.biochemistry }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      cervical Cytology
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.cervical_cytology }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      profile Test
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.profile_test }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      hematology
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.hematology }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      microbiology
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.microbiology }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      anatomical Pathology
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.anatomical_pathology }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      additional Test
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.additional_test }}
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card outlined>
                    <v-card-subtitle class="mytitle" style="padding: 0px 16px">
                      anatomical Pathology
                    </v-card-subtitle>
                    <v-card-text>
                      {{ view.anatomical_pathology }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- end -->
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
import Restful from "@/services/RestFul";
export default {
  props: {
    id: { type: String },
    subscriber: { type: String },
    date: { type: String },
  },
  components: {},
  mixins: [WinScroll("position"), Utils],
  data() {
    return {
      tabs: null,
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "patient.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Past Appointment",
          to: { name: "patient.history" },
        },
        {
          exact: true,
          link: true,
          text: "Past Appointment",
          to: { name: "patient.history.detail" },
        },
      ],
      details: {},
      moreList: [],
      headers: [
        { text: "condition", value: "medics_request" },
        { text: "routine drugs", value: "routine_drugs" },
        { text: "allergies", value: "allergies" },
      ],
      evidenceList: [],
      preheaders: [
        { text: "category", value: "pharma_category" },
        { text: "specify medication", value: "medi_specify" },
        { text: "strength", value: "strength" },
        { text: "quantity", value: "quantity" },
        { text: "frequency", value: "frequency" },
        { text: "refills", value: "refills" },
      ],
      prescribeList: [],
      view: {},
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.initialComplaints();
    this.moreInfo();
    this.evidence();
    this.prescribe();
    this.labs();
  },
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
    initialComplaints() {
      this.loading = true;
      let self = this;
      let id = parseInt(this._decode(this.id));
      Restful.medics.patient
        .appointDetails(id)
        .then((response) => {
          console.log(response.data);
          this.details = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    moreInfo() {
      this.loading = true;
      let self = this;
      let id = parseInt(this._decode(this.id));
      Restful.medics.patientMoreInfo
        .list(id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.moreList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    evidence() {
      this.moreInfos = [];
      this.overlay = true;
      let self = this;
      let d = this._decode(this.id);
      console.log("d", d);
      Restful.medics.evidence
        .list(d)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.evidenceList = response.data;
            this.overlay = false;
          } else if (response.status === 204) {
            this.evidenceList = [];
            this.overlay = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
          self.overlay = false;
          self.overlay = false;
        });
    },
    pix(img) {
      if (img === "") {
        return "";
      } else {
        return img;
      }
    },
    prescribe() {
      let id = parseInt(this._decode(this.id));
      Restful.medics.treatment.prescription
        .list(id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.prescribeList = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    labs() {
      console.log("lab detail");
      let id = parseInt(this._decode(this.id));
      this.loading = true;
      let self = this;
      Restful.medics.treatment.labs
        .list(id)
        .then((response) => {
          console.log(response);
          this.view = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
  },
};
</script>
